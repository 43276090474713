export const PARALLEL_TASKS = 3;
export const TEAM_MEMBERS = ["Jeff", "Cassandra", "Derek", "Jen", "Katie"];

export default function getName() {
  return "Jeff";
}

export function generateMembers() {
  return TEAM_MEMBERS;
}
