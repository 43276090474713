import GanttWithControls from "../components/GanttWithControls";
import { generateParallelTasksInTeam } from "../generators/team";
import getName, { generateMembers } from "../members";

const name = getName();
const members = generateMembers();

export default function Teams() {
  return (
    <>
      <h1>The Dangers of Parallel Projects - Teams</h1>
      <h2>Individuals joining a team</h2>
      <p>
        {name} used to work by himself, but has recently been made part of a
        team
      </p>
      <p>
        {name} started to fall into old habits, picking up work from various
        places.
      </p>
      <p>
        Lets see what happens when {name} picks up more projects at the same
        time.
      </p>
      <GanttWithControls generator={generateParallelTasksInTeam} />
      <p>Oh no! {name} has inadvertantly delayed the project!</p>
      <h2>Working as a team</h2>
      <p>
        Thankfully, {name} has learnt how to deliver quickly as part of a team,
        focusing on delivering value quickly.
      </p>
      <p>{name} has started sharing projects out across the team, allowing others to learn from them.</p>
      <p>This is great as now other team members like {members[1]} and {members[2]} can learn from {name} through practical application.</p>
      <p>Previously {name} had to run lots of knowledge sharing sessions, but because they work together, they learn together! Wow!</p>
    </>
  );
}
