export const teamTaskStyle = {
  barBackgroundColor: "#37AFA9",
  barSelectedColor: "#37AFA9",
};

export const jeffTaskStyle = {
  barBackgroundColor: "#FFB400",
  barSelectedColor: "#FFB400",
};

export const urgentTaskStyle = {
  barBackgroundColor: "#CA3542",
  barBackgroundSelectedColor: "#CA3542",
};
