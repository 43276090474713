import { generateParallelTasks, generateParallelTasksWithInterrupt, generateSerialTasks, generateSerialTasksWithInterrupt } from "../generators/individual";
import GanttWithControls from "../components/GanttWithControls";
import getName from "../members";

const name = getName();

export default function Individual() {
  return (
    <>
      <h1>The Dangers of Parallel Projects - Individuals</h1>
      <p>{name} has been asked to manage a number of projects, but he is struggling to get things done.</p>
      <p>It seemed simple to keep up with all the tasks initially, but as time went on, he found it harder and harder to keep up.</p>
      <p>Use the buttons below to see how {name} was spreading the effort across each task:</p>
      <GanttWithControls generator={generateParallelTasks} />
      <p>{name}'s stakeholders wonder when things will get done, and {name} struggles to articulate how much time he's spending on each project.</p>
      <p>Could you accurately tell your stakeholders how much time you're spending on each project?</p>
      <h2>Urgent Work</h2>
      <p>{name} has some urgent work coming in! Can he drop what he's doing effectively and move to deal with changing requirements?</p>
      <GanttWithControls generator={generateParallelTasksWithInterrupt} />
      <p>Do you think you could restart this many projects effectively?</p>
      <h2>Serial Work</h2>
      <p>{name} decides to focus on one task at a time, knowing that in either case he'll get everything done.</p>
      <GanttWithControls generator={generateSerialTasks} />
      <p>{name}'s stakeholders are impresseed by how often {name} is delivering, often hearing of new things that {name} has done, this builds confidence in {name}'s ability to deliver.</p>
      <h2>More Urgent Work</h2>
      <p>Yet again {name} is asked to pick up an important task, let's look at the impact now:</p>
      <GanttWithControls generator={generateSerialTasksWithInterrupt} />
      <p>{name} can happily focus on what's most important to get done. Yay {name}!</p>
      <p>{name}'s stakeholders were also happy, not only was {name} constantly telling them how much he delivered, but he also responded quickly when they had new demands!</p>
    </>
  );
};