import React, { useState } from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Column, Gantt, Task, ViewMode } from "@wamra/gantt-task-react";
import { theme } from "../theme";

interface GanttWithControlsProps {
  generator: (n: number) => Task[];
}

const GanttWithControls: React.FC<GanttWithControlsProps> = ({ generator }) => {
  const MAX_TASKS = 5;
  const columns: Column[] = [];
  const [tasks, setTasks] = useState<number>(1);

  const taskDecrease = () => {
    setTasks(Math.max(tasks - 1, 1));
  };
  const taskIncrease = () => {
    setTasks(Math.min(tasks + 1, MAX_TASKS));
  };
  return (
    <>
      <>
        <Button variant="contained" onClick={() => taskIncrease()}>
          <AddIcon />
          Add Project
        </Button>
        <Button
          variant="contained"
          onClick={() => taskDecrease()}
          style={{ marginLeft: theme.spacing(1) }}
        >
          <RemoveIcon />
          Remove Project
        </Button>
      </>
      <Gantt
        viewMode={ViewMode.Week}
        tasks={generator(tasks)}
        columns={columns}
      />
    </>
  );
};

export default GanttWithControls;
