import { Task } from "@wamra/gantt-task-react";
import { addDays } from "./days";

import { jeffTaskStyle, urgentTaskStyle } from "./styles";

export function generateSerialTasksWithInterrupt(n: number): Task[] {
  let tasks: Task[] = [];

  const baseDate = new Date(2020, 0, 1);

  const taskLength = 10;
  const urgentTaskLength = 7;
  const urgentTaskStart = 7;
  const weekends = 2 * Math.floor((taskLength - 1) / 5);

  const initialWeekends = 2 * Math.floor((urgentTaskStart - 1) / 5);
  const interruptDate = addDays(baseDate, 7 + initialWeekends);

  {
    const i = 0;
    const start = new Date(2020, 0, 1);
    const end = addDays(start, urgentTaskStart - 1 + initialWeekends);
    tasks.push({
      start,
      end,
      name: `Project ${i}`,
      id: `Task ${i}`,
      type: "task",
      progress: 0,
      isDisabled: true,
      styles: jeffTaskStyle,
    });
  }

  tasks.push({
    start: interruptDate,
    end: addDays(interruptDate, urgentTaskLength),
    name: "Urgent Task",
    id: "Urgent Task",
    type: "task",
    progress: 0,
    isDisabled: true,
    styles: urgentTaskStyle,
  });

  const restartDate = addDays(interruptDate, urgentTaskLength + 1);
  {
    const i = 0;
    const restartDate = addDays(interruptDate, urgentTaskLength + 1);
    const start = restartDate;
    const end = addDays(
      restartDate,
      taskLength + weekends - urgentTaskStart - 1
    );
    tasks.push({
      start,
      end,
      name: `Project ${i}`,
      id: `Resume ${i}`,
      type: "task",
      progress: 0,
      isDisabled: true,
      styles: jeffTaskStyle,
    });
  }

  let start = addDays(restartDate, taskLength + weekends - urgentTaskStart - 1);

  for (let i = 1; i < n; i++) {
    const end = addDays(start, taskLength + weekends);
    tasks.push({
      start,
      end,
      name: `Project ${i}`,
      id: `Task ${i}`,
      type: "task",
      progress: 0,
      isDisabled: true,
      styles: jeffTaskStyle,
    });

    start = addDays(start, taskLength + weekends + 2);
  }

  return tasks;
}

export function generateSerialTasks(n: number): Task[] {
  let tasks: Task[] = [];
  const taskLength = 10;
  const weekends = 2 * Math.floor((taskLength - 1) / 5);

  let start = new Date(2020, 0, 1);

  for (let i = 0; i < n; i++) {
    const end = addDays(start, taskLength + weekends);
    tasks.push({
      start,
      end,
      name: `Project ${i}`,
      id: `Task ${i}`,
      type: "task",
      progress: 0,
      isDisabled: true,
      styles: jeffTaskStyle,
    });

    start = addDays(start, taskLength + weekends + 2);
  }
  return tasks;
}

export function generateParallelTasksWithInterrupt(n: number): Task[] {
  let tasks: Task[] = [];

  const baseDate = new Date(2020, 0, 1);

  const taskLength = 10;
  const urgentTaskLength = 7;
  const urgentTaskStart = 7;
  const adjustedTaskLength = taskLength * n;
  const weekends = 2 * Math.floor((adjustedTaskLength - 1) / 5);

  const initialWeekends = 2 * Math.floor((urgentTaskStart - 1) / 5);
  const interruptDate = addDays(baseDate, 7 + initialWeekends);

  for (let i = 0; i < n; i++) {
    const start = new Date(2020, 0, 1);
    const end = addDays(start, urgentTaskStart - 1 + initialWeekends);
    tasks.push({
      start,
      end,
      name: `Project ${i}`,
      id: `Task ${i}`,
      type: "task",
      progress: 0,
      isDisabled: true,
      styles: jeffTaskStyle,
    });
  }

  tasks.push({
    start: interruptDate,
    end: addDays(interruptDate, urgentTaskLength),
    name: "Urgent Task",
    id: "Urgent Task",
    type: "task",
    progress: 0,
    isDisabled: true,
    styles: urgentTaskStyle,
  });

  const restartDate = addDays(interruptDate, urgentTaskLength + 1);
  for (let i = 0; i < n; i++) {
    const start = restartDate;
    const end = addDays(
      restartDate,
      adjustedTaskLength + weekends - urgentTaskStart - 1
    );
    tasks.push({
      start,
      end,
      name: `Project ${i}`,
      id: `Resume ${i}`,
      type: "task",
      progress: 0,
      isDisabled: true,
      styles: jeffTaskStyle,
    });
  }

  return tasks;
}

export function generateParallelTasks(n: number): Task[] {
  let tasks: Task[] = [];
  const taskLength = 10;
  const adjustedTaskLength = taskLength * n;
  const weekends = 2 * Math.floor((adjustedTaskLength - 1) / 5);

  for (let i = 0; i < n; i++) {
    const start = new Date(2020, 0, 1);
    const end = addDays(start, adjustedTaskLength + weekends);
    tasks.push({
      start,
      end,
      name: `Project ${i}`,
      id: `Task ${i}`,
      type: "task",
      progress: 0,
      isDisabled: true,
      styles: jeffTaskStyle,
    });
  }
  return tasks;
}
