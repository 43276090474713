import { createRoot } from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Individual from "./routes/Individual";
import Teams from "./routes/Teams";
import "@wamra/gantt-task-react/dist/style.css";
import { theme } from "./theme";
const router = createBrowserRouter([
  {
    path: "/individual",
    element: <Individual />,
  },
  {
    path: "/teams",
    element: <Teams />,
  },
]);

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container>
        <RouterProvider router={router} />
      </Container>
    </ThemeProvider>
  );
};

const domNode = document.getElementById("root") as HTMLElement;
const root = createRoot(domNode);
root.render(<App />);
