import { Dependency, Task } from "@wamra/gantt-task-react";
import { addDays } from "./days";

import { teamTaskStyle, jeffTaskStyle } from "./styles";
import getName, { generateMembers, PARALLEL_TASKS } from "../members";

export function generateParallelTasksInTeam(
  n: number,
  teamTasks: number = 5
): Task[] {
  let tasks: Task[] = [];
  const taskLength = 10;
  const weekends = 2 * Math.floor((taskLength - 1) / 5);
  const adjustedTaskLength = taskLength * n;
  const adjustedWeekends = 2 * Math.floor((adjustedTaskLength - 1) / 5);
  const projectStart = new Date(2020, 0, 1);

  let jeffCounter = 0;
  // Jeff starts with team tasks
  for (let i = 0; i < n; i++) {
    const start = new Date(2020, 0, 1);
    const end = addDays(start, adjustedTaskLength + adjustedWeekends);
    const styles = i === 0 ? teamTaskStyle : jeffTaskStyle;
    tasks.push({
      start,
      end,
      name: `Jeff ${i}`,
      id: `Jeff ${i}`,
      type: "task",
      progress: 0,
      isDisabled: true,
      styles,
    });
    jeffCounter++;
  }

  const parallelTickets = PARALLEL_TASKS;
  const team = generateMembers();
  console.log(team);
  let dependencies: Dependency[] = [{
    sourceId: `${getName()} 0`,
    sourceTarget: 'endOfTask',
    ownTarget: 'startOfTask',
  }];
  let i = 1;

  for (; i < parallelTickets; i++) {
    const member = team[i % parallelTickets];
    const memberTaskLength =
      member === getName()
        ? adjustedTaskLength + adjustedWeekends
        : taskLength + weekends;
    const start = projectStart;
    const end = addDays(start, memberTaskLength);
    tasks.push({
      start,
      end,
      name: `${member} 0`,
      id: `Task ${i}`,
      type: "task",
      progress: 0,
      isDisabled: true,
      styles: teamTaskStyle,
    });
    dependencies.push({
      sourceId: `Task ${i}`,
      sourceTarget: 'endOfTask',
      ownTarget: 'startOfTask',
    })
  }

  for (; i < teamTasks; i++) {
    const member = team[i % parallelTickets];
    const start = addDays(projectStart, adjustedTaskLength + adjustedWeekends);
    const end = addDays(start, taskLength + weekends);
    const memberTaskId = member === "Jeff" ? `Jeff ${jeffCounter}` : `${member} 1`;
    if (member === "Jeff") {
      jeffCounter++;
    }
    tasks.push({
      start,
      end,
      name: memberTaskId,
      id: memberTaskId,
      type: "task",
      progress: 0,
      dependencies,
      isDisabled: true,
      styles: teamTaskStyle,
    });
  }
  return tasks;
}
